$xr-green:      #21a73d !default;
$xr-yellow:      #ffc113 !default;
$xr-light-blue:      #71d0f1 !default;
$xr-pink:      #ed9bc4 !default;
$xr-purple:      #986297 !default;
$xr-dark-blue:      #3f61b6 !default;
$xr-light-green:      #a1da68 !default;
$xr-pastel-yellow:      #f3f35b !default;

// Other colours
$black-color:       #111 !default;
$white-color:       #fdfdfd !default;
$shadow-color:     #222;
$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;


$text-color:       $black-color !default;



    $brand-text-color: $black-color !default;
    $brand-text-color-invert: $white-color !default;



    $accent-text-color: $black-color !default;

$text-color-light: lighten($text-color, 18%);
$background-color: $white-color !default;
$brand-color:       $xr-green !default;
$accent-color:      $xr-yellow !default;
$accent-color-dark: darken($accent-color, 15%) !default;

$on-tiny:          250px !default;
$on-small:         425px !default;
$on-medium:        850px !default;
$on-large:         1300px !default;

@import "rebellion";