// Define defaults for each variable.

@font-face{
    font-family:"FucXed";
    font-style:normal;
    font-weight:normal;
    src:url("../fucxedcaps-v2-webfont.woff2") format("woff2"),url("../assets/fucxedcaps-v2-webfont.woff") format("woff");
    font-display:swap
}

// Fonts
$base-font-stack: "Crimson Text", serif !default;
$base-font-size:   16pt !default;
$base-font-weight: 400 !default;
$base-line-height: 1.5 !default;
$header-font-stack: "FucXed","Helvetica Neue",Helvetica,Arial,sans-serif;
$small-font-size:  $base-font-size * 0.875 !default;
$smaller-font-size:  $base-font-size * 0.7 !default;

$table-text-align: left !default;

// Width of the content area
$content-width-narrow:    400px !default;
$content-width-wide:    600px !default;
$half-width:    500px !default;

$on-small:         400px !default;
$on-medium:        800px !default;
$on-large:         1300px !default;

$spacing-unit:     10px !default;
$action-bar-height: 72px !default;
$action-bar-height-mobile: 56px !default;
$rounding: 5px;

// Import partials.
@import
  "rebellion/base",
  "rebellion/header",
  "rebellion/footer",
  "rebellion/content",
  "rebellion/print"
;
    